import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { limitsService } from "@api/services";
import { ConfigsType } from "@common/Types";

export const useGetCityConfigs = (cityId: string) => {
  return useQuery<ConfigsType>({
    queryKey: [QUERY_KEYS.CONFIGS, cityId],
    queryFn: async () => await limitsService.getCityConfigs(cityId),
  });
};
