import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { isTargetNavi } from "@assets/Flags";
import { ConfigsType, initialParams } from "@common/Types";
import { Block, FormLimitField, FormSwitchField } from "@components/Shared";

import { ButtonActionLimits } from "../../components/shared/button-action-limits";
import { riderParamsSchema } from "../validation";

type Props = {
  initialParams: initialParams;
  updateParams: (values: initialParams) => void;
};

export const RiderParams = ({ initialParams, updateParams }: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = async (values: initialParams) => {
    const loading_toast = toast.loading("Загрузка");

    try {
      await updateParams(values);
      setIsEdit(false);

      toast.success(`Лимиты успешно изменены`, {
        id: loading_toast,
      });
    } catch (error) {
      toast.remove(loading_toast);
    }
  };

  const onCloseForm = (callback: () => void) => {
    setIsEdit(false);
    callback();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={riderParamsSchema}
      initialValues={initialParams}
      onSubmit={onSubmitForm}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Block
              title="Настройки пассажира"
              renderRightItem={
                <ButtonActionLimits
                  isEdit={isEdit}
                  onClose={() => onCloseForm(resetForm)}
                  onOpen={() => setIsEdit(true)}
                />
              }
            >
              <div className="space-y-6 pt-4">
                <FormLimitField<ConfigsType>
                  title="Оплата бонусами"
                  isEdit={isEdit}
                  params={{
                    name: "Процент оплаты бонусами",
                    field: "max_bonus_payment_percentage",
                  }}
                />

                <FormSwitchField<ConfigsType>
                  isEdit={isEdit}
                  params={[
                    { name: "Добавление карты VISA", field: "save_card_visa" },
                    {
                      name: "Создание поездки с оплатой VISA",
                      field: "create_ride_by_visa",
                    },
                    isTargetNavi
                      ? {
                          name: "Создание поездки с оплатой Mbank",
                          field: "create_ride_by_mbank",
                        }
                      : {
                          name: "Создание поездки с оплатой Kaspi.kz",
                          field: "create_ride_by_kaspi",
                        },
                  ]}
                />
              </div>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};
