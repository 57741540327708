import { useField } from "formik";
import React from "react";

import { Params } from "@common/Types";

import { FormLimitField } from "../form-limit-field";
import { FormSwitchField } from "../form-switch-field";

type Props<T> = {
  name: string;
  field: keyof T | null;
  isEdit: boolean;
  params: Params<T>[] | Params<T>;
};

export const FormExtendedSwitchField = <T,>({
  name,
  field,
  isEdit,
  params,
}: Props<T>) => {
  const [fieldProps] = useField(String(field));

  const currentValue = fieldProps.value ?? 0;

  return (
    <div className="bg-neutral-bg-50 rounded-lg">
      <FormSwitchField
        isExtended={true}
        isEdit={isEdit}
        params={[
          {
            name: name,
            field: field,
          },
        ]}
      />

      {!!currentValue && (
        <FormLimitField isExtended={true} isEdit={isEdit} params={params} />
      )}
    </div>
  );
};
