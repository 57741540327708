import { isEqual } from "radash";
import React from "react";

import {
  useGetParkDetail,
  useGetParkFees,
  useUpdateFee,
  useUpdateParkConfigs,
} from "@api/queries";
import { initialParams } from "@common/Types";
import { Loader } from "@components/Elements";

import { ParkParams } from "./params/park-params";
import { WithdrawCommissionParams } from "./params/withdraw-commission-params";

type Props = {
  parkId: string;
};

// TODO: move this component to separate file later
export const ParkSettingsCommon = ({ parkId }: Props) => {
  const { data: parkFees, isPending: parkFeesLoading } = useGetParkFees(parkId);
  const { data: parkDetails, isPending: parkLoading } =
    useGetParkDetail(parkId);

  const { mutateAsync: updateParkConfig } = useUpdateParkConfigs();
  const { mutateAsync: updateFee } = useUpdateFee();

  const updateParams = async (params: initialParams) => {
    const { fees, ...configs } = params;

    await updateParkConfig({ parkId, configs });

    const updatedFees = fees.filter((fee, index) => {
      const initialFee = initialParams.fees[index];
      return !isEqual(fee, initialFee);
    });

    if (updatedFees.length > 0) {
      await Promise.all(updatedFees.map((fee) => updateFee(fee)));
    }
  };

  const initialParams: initialParams = {
    ...parkDetails?.configs,
    fees: parkFees?.configs ?? [],
  };

  return (
    <Loader
      loading={parkLoading || parkFeesLoading}
      height={610}
      className="mt-6"
    >
      <div className="space-y-6">
        <ParkParams initialParams={initialParams} updateParams={updateParams} />

        <WithdrawCommissionParams
          initialParams={initialParams}
          updateParams={updateParams}
        />
      </div>
    </Loader>
  );
};
