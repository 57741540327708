import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { ConfigsType, initialParams } from "@common/Types";
import {
  Block,
  FormExtendedSwitchField,
  FormSwitchField,
} from "@components/Shared";

import { ButtonActionLimits } from "../../components/shared/button-action-limits";
import { WithdrawFields } from "../../components/shared/withdraw-fields";
import { driverParamsSchema } from "../validation";

type Props = {
  initialParams: initialParams;
  updateParams: (values: initialParams) => void;
};

export const DriverParams = ({ initialParams, updateParams }: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = async (values: initialParams) => {
    const loading_toast = toast.loading("Загрузка");

    try {
      await updateParams(values);
      setIsEdit(false);

      toast.success(`Лимиты успешно изменены`, {
        id: loading_toast,
      });
    } catch (error) {
      toast.remove(loading_toast);
    }
  };

  const onCloseForm = (callback: () => void) => {
    setIsEdit(false);
    callback();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={driverParamsSchema}
      initialValues={initialParams}
      onSubmit={onSubmitForm}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Block
              title="Настройки водителя"
              renderRightItem={
                <ButtonActionLimits
                  isEdit={isEdit}
                  onClose={() => onCloseForm(resetForm)}
                  onOpen={() => setIsEdit(true)}
                />
              }
            >
              <div className="space-y-6 pt-4">
                <WithdrawFields
                  type="ride_payment"
                  configs={initialParams.fees}
                  isEdit={isEdit}
                />

                <FormExtendedSwitchField<ConfigsType>
                  name="Нулевая комиссия"
                  field="zero_commission_enabled"
                  isEdit={isEdit}
                  params={{
                    name: "Количество заказов",
                    field: "zero_commission_minimum",
                  }}
                />

                <FormExtendedSwitchField<ConfigsType>
                  name="Блокировка водителя"
                  field="driver_ride_ignore_limit_enabled"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Количество пропусков",
                      field: "driver_ride_ignore_limit_number",
                    },
                    {
                      name: "Минуты",
                      field: "driver_ride_ignore_block_minutes",
                    },
                  ]}
                />

                <FormSwitchField<ConfigsType>
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Показывать точку Б",
                      field: "show_ride_points_in_driver_offer",
                    },
                    {
                      name: "Показывать посадку",
                      field: "show_ride_estimation_in_driver_offer",
                    },
                  ]}
                />
              </div>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};
