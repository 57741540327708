import { httpClient } from "@common/Libs/axios";
import {
  CreateDriverType,
  DriverParams,
  DriverType,
  DriverTypes,
  LandingDriverApplicationType,
  ParksFilterType,
} from "@common/Types";
import { buildCommonFiltersValues, commaSerializer } from "@common/Utils";

import { driverPath } from "./paths";

/**
 * Описание методов drivers.
 */

export const driversService = {
  getDriverLocation: (filters?: ParksFilterType) =>
    httpClient.get<DriverTypes>(driverPath.locations, {
      params: buildCommonFiltersValues(filters),
      paramsSerializer: commaSerializer,
    }),
  getDrivers: ({ limit, skip, filters }: DriverParams) => {
    const buildFilter = buildCommonFiltersValues(filters);

    return httpClient.get<DriverType>(driverPath.drivers, {
      params: { limit, skip, ...buildFilter },
      paramsSerializer: commaSerializer,
    });
  },
  sendDriverLandingApplication: (application: LandingDriverApplicationType) =>
    httpClient.post(driverPath.support_applications, application),
  createDriver: (driver: CreateDriverType) =>
    httpClient.post<CreateDriverType, CreateDriverType>(
      driverPath.createDriver,
      driver,
    ),
};
