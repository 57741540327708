import { isEqual } from "radash";
import React from "react";

import {
  useGetCityConfigs,
  useGetCityFees,
  useUpdateCityConfigs,
  useUpdateFee,
} from "@api/queries";
import { ROLES } from "@common/Constants";
import { initialParams } from "@common/Types";
import { getUserRole } from "@common/Utils";
import { Loader } from "@components/Elements";

import { DriverParams } from "./params/driver-params";
import { OrderRouteParams } from "./params/order-route-params";
import { RiderParams } from "./params/rider-params";
import { WithdrawCommissionParams } from "./params/withdraw-commission-params";

type Props = {
  cityId: string;
  isBishkek: boolean;
};

// TODO: move this component to separate file later
export const LimitsSettingsCommon = ({ cityId, isBishkek }: Props) => {
  const userRole = getUserRole();
  const isSuperAdmin = userRole === ROLES.SUPERADMIN;
  const { data: configs, isPending: configsLoading } =
    useGetCityConfigs(cityId);
  const { data: cityFees, isLoading: cityFeesLoading } = useGetCityFees({
    cityId,
    enabled: isSuperAdmin,
  });

  const { mutateAsync: updateConfig } = useUpdateCityConfigs();
  const { mutateAsync: updateFee } = useUpdateFee();

  const updateParams = async (params: initialParams) => {
    const { fees, ...configs } = params;

    await updateConfig({ cityId, configs });

    const updatedFees = fees.filter((fee, index) => {
      const initialFee = initialParams.fees[index];
      return !isEqual(fee, initialFee);
    });

    if (updatedFees.length > 0) {
      await Promise.all(updatedFees.map((fee) => updateFee(fee)));
    }
  };

  const initialParams: initialParams = {
    ...configs,
    fees: cityFees?.configs ?? [],
    perDay: 1,
  }; //adds the missing static field with frequency for "to home", "to business"

  return (
    <Loader
      loading={configsLoading || cityFeesLoading}
      height={609.5}
      className="mt-6"
    >
      <div className="space-y-6">
        <OrderRouteParams
          initialParams={initialParams}
          updateParams={updateParams}
          isBishkek={isBishkek}
        />

        <DriverParams
          initialParams={initialParams}
          updateParams={updateParams}
        />

        <WithdrawCommissionParams
          initialParams={initialParams}
          updateParams={updateParams}
        />

        <RiderParams
          initialParams={initialParams}
          updateParams={updateParams}
        />
      </div>
    </Loader>
  );
};
